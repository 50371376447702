<template>
  <div>
    <div class="bg-gray-800 text-gray-200">
      <div class="flex flex-wrap">
        <div class="w-full">
          <div class="p-1 text-xs border-b border-gray-700 pb-2">
            <div class="flex m-2">
              <span class="flex-1 inline-block">
                <span
                  class="px-2 pb-1 bg-gray-700 rounded-full text-gray-200 mb-1 cursor-pointer hover:bg-gray-600 hover:text-white"
                  @click.prevent="onHighlight(group.id)"
                >
                  <fa-icon
                    icon="circle"
                    :class="[ isHighlighted ? 'text-white' : 'text-gray-800' ]"
                    style="margin: -1px"
                    size="sm"
                  />
                  {{ group.name }}
                </span>
              </span>

              <div class="flex-1 text-xs text-white text-right">
                <v-popover>
                  <button
                    class="btn btn-no-style focus:outline-none focus:text-white text-gray-600 rounded-full py-1 -mt-2"
                    @click.prevent
                  >
                    <fa-icon icon="ellipsis" />
                  </button>

                  <template slot="popover">
                    <ul class="text-white text-sm leading-loose">
                      <li>
                        <a
                          v-close-popover
                          href="#"
                          class="flex justify-between items-center hover:underline"
                          @click.prevent="showRenameModal"
                        >
                          Rename Group
                          <fa-icon
                            icon="pencil"
                            class="pl-1"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="flex justify-between items-center hover:underline"
                          @click.prevent="viewMapping(group.id)"
                        >
                          Edit Maps
                          <fa-icon
                            icon="pen-to-square"
                            class="pl-1"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          v-close-popover
                          href="#"
                          class="flex justify-between items-center hover:underline text-red-500"
                          @click.prevent="showDeleteModal"
                        >
                          Delete Group
                          <fa-icon
                            icon="trash-can"
                            class="pl-1"
                          />
                        </a>
                      </li>
                    </ul>
                  </template>
                </v-popover>
              </div>
            </div>

            <div
              class="block text-xs px-2 italic text-gray-500 hover:underline cursor-pointer"
              @click.prevent="viewMapping(group.id)"
            >
              <span v-if="group.selected.length > 0">
                <span class="text-white">{{ group.selected.length }} maps:</span> <span
                  v-for="item in group.selected"
                  :key="item"
                >{{ item }} </span>
              </span>
              <span v-else>
                <span class="text-white">0 maps</span> <span
                  v-if="isSimple"
                  class="ml-2"
                >Select mappings &rarr;</span>
              </span>
            </div>

            <div v-if="isFull">
              <MappingSingleBlock
                :meshes="meshes"
                :highlighted="group.selected"
                @highlight="onHighlightSingle"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const MappingSingleBlock = () => import('./MappingSingleBlock')
const MappingGroupDelete = () => import('@modals/MappingGroupDelete')
const MappingRename = () => import('@modals/MappingRename')

export default {
  name: 'MappingGroupBlock',

  components: {
    MappingSingleBlock
  },

  props: ['meshes', 'group', 'mode', 'highlightedGroup'],

  data () {
    return {
      viewer: null,
      meshHelper: null
    }
  },

  computed: {

    isHighlighted () {
      if (this.highlightedGroup === this.group.name) return true
      return false
    },

    available () {
      let opts = []

      this.meshes.forEach(avail => {
        avail.uvs.forEach(uv => {
          opts.push(avail.name + ':' + uv)
        })
      })

      return opts
    },

    selected () {
      return []
    },

    highlightedMesh () {
      if (this.meshHelper !== null) return this.meshHelper.highlight
      return null
    },

    isFull () {
      return this.mode === 'full'
    },

    isSimple () {
      return !this.isFull
    }
  },

  mounted () {
    this.viewer = window.previzviewer
    this.meshHelper = this.viewer.core.gui.meshHelper
  },

  methods: {

    showDeleteModal () {
      this.$modal.show(
        MappingGroupDelete,
        {
          group: this.group,
          callback: this.deleteCallback
        }, {
          height: 'auto',
          width: '400px',
          scrollable: true
        })
    },

    showRenameModal () {
      this.$modal.show(
        MappingRename,
        {
          item: this.group
        },
        {
          height: 'auto',
          width: '400px',
          scrollable: true
        })
    },

    deleteCallback () {
      this.$emit('delete', this.group.id)
      this.$modal.hide(MappingGroupDelete)
    },

    onHighlight (id) {
      this.$emit('highlight', id)
    },

    onHighlightSingle (meshname, uv) {
      console.log('SINGLE:: On highlight change in group from single.')
      // // Add or remove this item from the selected set
      // if(this.group.selected.indexOf(meshname) > -1) {
      //   this.meshHelper.unhighlightMesh(meshname)
      // } else {
      //   this.meshHelper.highlightMesh(meshname)
      // }
    },

    viewMapping (id) {
      this.$emit('view', { id: id })
    },

    viewSingleEdit (mesh) {
      this.singlemesh = mesh
    }
  }

}

</script>
